import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Slider from "react-slick";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import Lightbox from "react-image-lightbox";
import {isMobile} from "react-device-detect";
import Image from 'next/image';


// It's allowed to have CSS from node_modules packages - this will also work
// for NextJS components import.
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";

/*
* !!! DO NOT ALTER the styles here. These are the base for all other slider type
*  components. Override specific styles there, inside specific components.
*/
const stylesHOC = (theme) => ({
  rootCarousel: {
    minHeight: 100,
    position: 'relative',
    // backgroundColor: 'white',
    zIndex: 100,
    "& img:hover": {
      cursor: 'pointer',
      opacity: .9
    },
    "& img": {
      width: '100%',
      height: '100%',
      // minHeight: 135,
      objectFit: 'cover',
      objectPosition: 'center center',
      [theme.breakpoints.up("md")]: {
        // minHeight: '200px'
      }
    },
    "& .slick-slide": {
      height: '100%',
    },

    "& .smallCarouselImage": {
      display: 'block !important',
      minHeight: '13.9vw !important',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        minHeight: '400px !important',
      },
    },
  },
  slickSlider: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto!important',
    position: 'relative',
    display: 'block',
    overflow: 'hidden'
  },
  slickNext: {
    right: 0
  },
  slickPrev: {
    left: 0
  },
  slickSliderInner: {},
  firstImage: {},
  slickImages: {
    "& .slick-slider": {
      zIndex: 1,
      position: 'relative',
    },
  },
  featuredImage: {
    "& img": {
      height: '100% !important',
    },
  },
  // For the <div> around the MAIN <img>:
  galleryImage: {
    // height: '400px',
    "& img": {
      // width: '100vw !important',
      // minWidth: '100vw !important',
      // maxWidth: '100vw !important',
      // height: '400px !important',
      // minHeight: '400px !important',
      // maxHeight: '400px !important',
    },
    "& span": {
      height: '100% !important',
    },
  },
  // For MAIN <img> element itself:
  slickImage: {},
  // For the <div> around the THUMB <img>:
  thumbImage: {},
  // For THUMB <img> element itself:
  slickThumbnail: {},
  // Wraps around the Thumbs navigation slider:
  thumbnailSliderWrap: {
    minHeight: 130,
  },
  slickArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 20,
    border: 'none',
    padding: '0',
    "&:hover": {
      cursor: 'pointer'
    }
  },
  borderBgLeft: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      height: '510px',
      width: '200px',
      top: 0,
      left: '6.5rem',
    },
  },
  borderBgRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }, [theme.breakpoints.up('md')]: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 41px',
      position: 'absolute',
      height: '520px',
      width: '200px',
      top: 42,
      right: '6.5rem',
    },
  },

});

const ConditionalWrapper = ({condition, wrapper, children}) => condition
    ? wrapper(children)
    : children;

function CarouselHOC(props) {
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setSlideNav({nav1: slider1.current, nav2: slider2.current});
  }, [slider1, slider2]);

  if (!props.slides && !props.children) {
    return null;
  }
  let {
    classes, slides, showLight,
    // if empty - always show thumbs, if not empty - hide thumbs responsively:
    showThumb,
    // if displaying content, but want image thumbs:
    contentThumbs,
    // if the main sliders should use image style derivative from Drupal:
    imgStyle,
    featured,
    slickSett,
    slickNavSett,
    children,
    sponsors,
    testimonialBackground,
    bannerSlider,
    margin,
    marginUnit,
    linkOut,
  } = props;

  let mt,
      mr,
      mb,
      ml,
      unit;
  if (margin) {
    unit = marginUnit || 'px';
    ([mt, mr, mb, ml] = margin);
  }

  function SampleNextArrow(props) {
    const {onClick} = props;
    return (<div className={`${classes.slickNext} ${classes.slickArrow}`}
                 onClick={onClick}><ChevronRightIcon/></div>);
  }

  function SamplePrevArrow(props) {
    const {onClick} = props;
    return (<div className={`${classes.slickPrev} ${classes.slickArrow}`}
                 onClick={onClick}><ChevronLeftIcon/></div>);
  }

  // The slides will be images or content?
  const showContent = props.children
      ? true
      : false;
  slides = showContent
      ? children
      : slides;

  const [activeSlide, setActiveSlide] = useState(0);
  const [photoIndex, setPhotoIndex] = useState({ind: null, src: ''});
  const [slideNav, setSlideNav] = useState({nav1: null, nav2: null});

  const slick_opts = {
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    beforeChange: (current, next) => {
      if (featured) {
        setActiveSlide(next);
      }
    },
    // mobileFirst: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: showThumb
              ? false
              : true,
          rows: 1,
          slidesPerRow: 1,
          speed: 600,
          centerMode: true,
          centerPadding: "0px"
        }
      }
    ]
  };
  const slick_nav_opts = {
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false
  };

  slickSett = slickSett
      ? {
        ...slick_opts,
        ...slickSett
      }
      : {
        ...slick_opts,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        centerMode: true,
        centerPadding: "0px",
        speed: 600
      };

  slickNavSett = slickNavSett
      ? {
        ...slick_nav_opts,
        ...slickNavSett
      }
      : slick_nav_opts;

  function handleClickImage(e, ind, image) {
    if (showLight) {
      e && e.persist() && e.preventDefault();
      setPhotoIndex({ind: ind, src: image.src});
    }
  }

  function handleCloseModal(e) {
    if (showLight) {
      e && e.persist() && e.preventDefault();
      setPhotoIndex({ind: null, src: ''});
    }
  }

  return (<div className={classes.rootCarousel}>
    <div className={classNames(featured ? classes.slickSlider : '')}>
      <div
          className={classNames(classes.slickSliderInner, featured ? 'featured' : '',
              (featured && activeSlide == 0) ? 'active' : '')}>
        {featured && <Hidden xsDown>
          <div className={classes.firstImage}>
            <div className={classes.featuredImage}>
              <div style={{position: 'relative', width: "100%", height: "27.8vw", }}>
                <Image
                  priority
                  width="720"
                  height="400"
                  style={{layout: 'responsive', width: "100%", height: "100% !important"}}
                  sizes="50vw"
                  onClick={(e) => handleClickImage(e, 0, slides[0])}
                  src={(imgStyle && slides[0]['image_styles']?.[imgStyle]?.href) ? slides[0]['image_styles']?.[imgStyle]?.href : slides[0].src}
                  alt={slides[0].imgAlt}
                  title={slides[0].imgTitle}/>
                </div>
            </div>
          </div>
        </Hidden>
        }
        <ConditionalWrapper condition={margin}
                            wrapper={children => <Box mt={`${mt}${unit}`}
                                                      mr={`${mr}${unit}`}
                                                      mb={`${mb}${unit}`}
                                                      ml={`${ml}${unit}`}>{children}</Box>}>
          <div className={classes.slickImages}>
            <Slider {...slickSett} ref={slider1} asNavFor={slideNav.nav2}>
              {
                slides.map((val, ind) => {
                  // Only FEATURED layout should not include 1st image in
                  // Slider on desktop. On desktop 1st slide has modified
                  // markup.
                  if (featured && ind == 0 && !isMobile) {
                    return null;
                  }
                  if (showContent) {
                    return (
                        <div key={`imgs-${ind}`} className={classes.galleryImage}>
                          <div key={`content-${ind}`}>
                            {val}
                          </div>
                        </div>);
                  }
                  const img_src = (imgStyle && val['image_styles']?.[imgStyle]?.href) ? val['image_styles']?.[imgStyle]?.href : val.src;
                  return (
                      <div key={`imgs-${ind}`} className={classes.galleryImage}>
                        <ConditionalWrapper
                            condition={linkOut && !showLight && val.href}
                            wrapper={children => <a href={val.href}
                                                    target={val.hreftarget}>{children}</a>}>
                          {!bannerSlider && !sponsors && img_src &&

                          <Box className="smallCarouselImage" position={`relative`}>
                            {/*<div style={{position: 'relative' }}>*/}
                              <Image style={{objectFit: 'cover', layout: 'fill'}}
                                    src={img_src} fill={true}
                                   alt={val.imgAlt} title={val.imgTitle}
                                   onClick={(e) => handleClickImage(e, ind, val)}/>
                              {/*</div>*/}
                          </Box>
                          }
                          {!bannerSlider && sponsors && img_src &&

                          <Box style={{
                            position: 'relative',
                            height: 100,
                            minHeight: 100
                          }}>
                            <span style={{position: 'relative', width: "150", height: "150", }}>
                                  <Image style={{
                                    objectFit: 'contain',
                                    layout: 'intrinsic'
                                  }} width="150" height="150" src={img_src}
                                   alt={val.imgAlt} title={val.imgTitle}
                                   onClick={(e) => handleClickImage(e, ind, val)}/>
                            </span>
                          </Box>
                          }
                          {bannerSlider && !sponsors && img_src &&

                          <Box>

                            {/* <Hidden smDown> */}
                            {/* <Image style={{objectFit: 'cover', layout: 'intrinsic'}} sizes='100vw' width="1440" height="400" src={img_src}
                                alt={val.imgAlt} title={val.imgTitle}
                                onClick={(e) => handleClickImage(e, ind, val)} /> */}
                            {/* </Hidden> */}
                            {/* <Hidden smUp> */}

                            <span style={{position: 'relative', width: "1400", height: "440", }}>
                                <Image priority style={{layout: 'intrinsic'}}
                                   sizes='100vw' width="1400" height="440"
                                   src={img_src}
                                   alt={val.imgAlt} title={val.imgTitle}
                                   onClick={(e) => handleClickImage(e, ind, val)}/>
                            </span>
                            {/* </Hidden> */}
                          </Box>
                          }
                        </ConditionalWrapper>
                      </div>);
                })
              }
            </Slider>

            <ConditionalWrapper
                condition={(showThumb !== undefined && showThumb !== true && showThumb)}
                wrapper={children =>
                    <Hidden {...showThumb}>{children}</Hidden>}>
              {
                (showThumb !== undefined && showThumb) &&
                <div className={classes.thumbnailSliderWrap}>
                  <Slider className={classNames('thumb-nav')} {...slickNavSett}
                          ref={slider2} asNavFor={slideNav.nav1}>
                    {(showContent && contentThumbs?.length > 1) && contentThumbs.map((val, ind) => {
                      const img_src = (imgStyle && val['image_styles']?.[imgStyle]?.href) ? val['image_styles']?.[imgStyle]?.href : val.src;
                      return (<div key={`imgs-${ind}`} style={{
                        position: 'relative',
                        overflow: 'hidden'
                      }} className={classes.thumbImage}>
                        {!sponsors && val.src &&
                        <span style={{position: 'relative', width: "80", height: "80", }}>
                          <Image style={{layout: 'fill'}} sizes='100vw' width="0"
                               height="0" src={img_src} fill={true}
                               alt={val.imgAlt} title={val.imgTitle}/>
                        </span>
                        }
                        {sponsors && val.src &&
                        <span style={{position: 'relative', width: "80", height: "80", }}>
                          <Image style={{layout: 'responsive'}} sizes="50vw"
                               width="80" height="80" style={{
                          width: "100%",
                          height: "auto",
                        }} className={classes.slickThumbnail} src={img_src}
                               alt={val.imgAlt} title={val.imgTitle}/>
                        </span>
                        }
                      </div>);
                    })
                    }
                    {/* {showContent && !contentThumbs?.length && slides.map((val, ind) => { */
                    } {/* return ( */
                  } {/* <div key={`content-${ind}`}> */
                  } {/* {val} */
                  } {/* </div> */
                  } {/* ); */
                  } {/* }) */
                  } {/* } */
                  }
                    {
                      (showContent === undefined || !showContent) && slides.map((val, ind) => {
                        // Only FEATURED layout should not include 1st image in
                        // Slider on desktop. On desktop 1st slide has modified
                        // markup.
                        const img_src = (imgStyle && val['image_styles']?.[imgStyle]?.href) ? val['image_styles']?.[imgStyle]?.href : val.src;
                        if (featured && ind == 0 && !isMobile) {
                          return null;
                        }
                        return (<div key={`imgs-${ind}`}
                                     className={classes.thumbImage}>
                          <Box className={classes.mainImage}>
                            <span style={{position: 'relative', width: "200", height: "200", }}>
                            <Image style={{layout: 'intrinsic'}} width="200"
                                   height="200" src={img_src}
                                   alt={val.imgAlt} title={val.imgTitle}/>
                            </span>
                          </Box>
                        </div>)
                      })
                    }
                  </Slider>
                </div>
              }

              {(showThumb !== undefined && showThumb) && null}
            </ConditionalWrapper>

            {(showLight && photoIndex?.src && !isNaN(photoIndex?.ind)) &&
            <Lightbox mainSrc={photoIndex.src}
                      onCloseRequest={(e) => handleCloseModal(e)}
                      nextSrc={slides[(photoIndex.ind + 1) % slides.length].src}
                      prevSrc={slides[(photoIndex.ind + slides.length - 1) % slides.length].src}
                      onMovePrevRequest={() => setPhotoIndex({
                        ind: (photoIndex.ind + slides.length - 1) % slides.length,
                        src: slides[(photoIndex.ind + slides.length - 1) % slides.length].src
                      })
                      }
                      onMoveNextRequest={() => setPhotoIndex({
                        ind: (photoIndex.ind + 1) % slides.length,
                        src: slides[(photoIndex.ind + 1) % slides.length].src
                      })
                      }/>
            }
            {testimonialBackground &&
            <Fragment key={`testimonial-borders-bg`}>
              <Box className={classes.borderBgLeft}>
                  <span style={{position: 'relative', width: "220", height: "515", }}>
                        <Image src="/assets/testimonial-bg-left.svg" width="220"
                       height="515" style={{layout: 'intrinsic'}}
                       sizes="100vw"/>
                  </span>
              </Box>
              <Box className={classes.borderBgRight}>
                            <span style={{position: 'relative', width: "220", height: "520", }}>
                <Image src="/assets/testimonial-bg-right.svg" width="220"
                       height="520" style={{layout: 'intrinsic'}}
                       sizes="100vw"/>
                            </span>
              </Box>
            </Fragment>
            }
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  </div>);
}

export default withStyles(stylesHOC, {withTheme: true})(CarouselHOC);
